var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-container"
  }, [_c('div', {
    staticClass: "pb-8px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "end",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('txt-primary', {
    staticClass: "txt--sm"
  }, [_c('span', {
    staticClass: "pink--text"
  }, [_vm._v("*")]), _vm._v(" 필수입력 항목입니다.")])], 1)], 1)], 1), _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('vertical-form-table', _vm._b({
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false)), _c('txt-primary', {
    staticClass: "my-12px my-lg-16px grey--text text--lighten-1"
  }, [_c('p', [_vm._v("※고객님의 개인정보는 개인정보보호법 및 정보통신망법에 따라 안전하게 보관되며 분양 완료 후 자동 파기됩니다.")]), _c('p', [_vm._v("※고객님의 소중한 정보는 분양 이외의 목적으로 사용되지 않습니다.")]), _c('p', [_vm._v("※관심고객으로 등록된 고객님의 정보는 분양정보를 안내하는데 사용합니다.")])]), _c('div', {
    staticClass: "pt-12px pt-lg-16px"
  }, [_c('terms-of-agreements-short', {
    attrs: {
      "params": {
        codes: ['collect-use', 'handle-consign', 'marketing-use']
      }
    },
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      }
    },
    model: {
      value: _vm.form._terms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_terms", $$v);
      },
      expression: "form._terms"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 min-w-sm-160px",
    attrs: {
      "tile": "",
      "x-large": "",
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("취소")])])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "w-100 min-w-sm-160px",
    attrs: {
      "tile": "",
      "x-large": "",
      "color": "grey darken-4"
    },
    on: {
      "click": _vm.validates
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("등록")])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }