<template>
    <div class="form-container">
        <div class="pb-8px">
            <v-row no-gutters justify="end" align="center">
                <v-col cols="auto">
                    <txt-primary class="txt--sm"><span class="pink--text">*</span> 필수입력 항목입니다.</txt-primary>
                </v-col>
            </v-row>
        </div>
        <v-form v-model="isValid">
            <vertical-form-table v-model="form" v-bind="{ items }"/>
            <txt-primary class="my-12px my-lg-16px grey--text text--lighten-1">
                <p>※고객님의 개인정보는 개인정보보호법 및 정보통신망법에 따라 안전하게 보관되며 분양 완료 후 자동 파기됩니다.</p>
                <p>※고객님의 소중한 정보는 분양 이외의 목적으로 사용되지 않습니다.</p>
                <p>※관심고객으로 등록된 고객님의 정보는 분양정보를 안내하는데 사용합니다.</p>
            </txt-primary>

            <div class="pt-12px pt-lg-16px">
                <terms-of-agreements-short :params="{ codes: ['collect-use', 'handle-consign', 'marketing-use'] }" v-model="form._terms" @isValid="(isValid) => (isTermsValid = isValid)" />
            </div>

            <div class="btn-wrap btn-wrap--lg">
                <v-row justify="center" class="row--sm">
                    <v-col cols="6" sm="auto">
                        <v-btn tile x-large outlined color="grey lighten-3" class="w-100 min-w-sm-160px" @click="$router.go(-1)"><span class="grey--text">취소</span></v-btn>
                    </v-col>
                    <v-col cols="6" sm="auto">
                        <v-btn tile x-large color="grey darken-4" class="w-100 min-w-sm-160px" v-bind="{ disabled }" @click="validates"><span class="white--text">등록</span></v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-form>
    </div>
</template>

<script>
import api from "@/api";
import { rules } from "@/assets/variables";
import { INTEREST_TYPE, GENDER_TYPE } from "@/assets/variables";

import PageSection from "@/sets/styles/pages/page-section.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import TermsOfAgreementsShort from "@/components/client/terms/terms-of-agreements-short.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import RadioPrimary from "@/components/publish/parents/forms/radio-primary.vue";
// import ImagesInput from "@/components/console/dumb/images-input.vue";
// import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";

const items = [
    {
        key: "name",
        term: "이름",
        type: "text",
        required: true,
        placeholder: "이름을 입력하세요",
    },
    {
        key: "birth",
        term: "생년월일",
        type: "text",
        required: true,
        placeholder: "생년월일 6자리를 입력해주세요",
    },
    {
        key: "phone",
        term: "연락처",
        type: "phone",
        required: true,
        placeholder: "‘-’를 제외하고 입력하세요.",
    },
    {
        key: "gender",
        term: "신청자격",
        type: "radio",
        required: true,
        radioItems: Object.values(GENDER_TYPE)
    },
    {
        key: "interest_type",
        term: "관심유형",
        type: "radio",
        required: true,
        radioItems: Object.values(INTEREST_TYPE)
    },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        PageSection,
        VerticalFormTable,
        TermsOfAgreementsShort,
        TxtPrimary,
        RadioPrimary,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            INTEREST_TYPE,
            GENDER_TYPE,
            form: this.$props.value,
            phoneItems: [
                { text: "010", value: "010" },
                { text: "070", value: "070" },
            ],
            items,
            isValid: false,
            isTermsValid: undefined,
            // phone1: "",
            // phone2: ""
        };
    },
    methods: {
        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }

                if (!this.form.name) throw new Error("이름을 입력해주세요.");
                if (!this.form.birth) throw new Error("생년월일을 입력해주세요.");
                if (!/^\d{6}$/.test(this.form.birth)) throw new Error("생년월일을 숫자 6자리로 입력해주세요.");
                if (!this.form.phone) throw new Error("연락처를 입력해주세요");
                if (!/^\d+$/.test(this.form.phone)) throw new Error("연락처는 숫자만 입력 가능합니다.");
                if (!this.form.gender) throw new Error("신청자격을 선택해주세요");
                if (!this.form.interest_type) throw new Error("관심유형을 선택해주세요");

                this.$emit("save", this.form);
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
    computed: {
        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
    },
};
</script>
